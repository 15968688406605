const merge = require('lodash.merge')

// Import the brand level theme so it can be merged.
const brandTheme = require('gatsby-theme-directv/theme')

// Merge the brand level theme and the site level theme.
// The second object being passed to the merge function
// is the site level theme. Anything placed here will
// override the brand level theme.
module.exports = merge(brandTheme, {
  colors: {
    primary: '#102641',
    secondary: '#2E70BD',
    tertiary: '#8CD3F5',
    dark: '#121212',
    light: '#FBFBFB',
    black: '#000000',
    white: '#FFFFFF',
    warning: '#FF7928',
    error: '#FF3068',
    success: '#2DE6BC',
    background1: '#FFFBF0',
    background2: '#FDD746',
    background3: '#F2FBFF',
    background4: '#21528C',
    background5: '#EAF1FA',
    background6: '#EFF1F3',
  },
  gradients: {
    primary: 'linear-gradient(to right, #102641, #121212)',
    secondary: 'linear-gradient(to right, #FBFBFB, #FFFFFF)',
    tertiary: 'linear-gradient(to right, #8CD3F5, #1EBCF8)',
  },
  backgroundColors: {
    primary: {
      textColor: '#fff',
    },
    secondary: {
      textColor: '#fff',
    },
    tertiary: {
      textColor: '#102641',
    },
    white: {
      textColor: '#102641',
    },
    light: {
      textColor: '#102641',
    },
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: 'PF Din Text Medium, Helvetica',
      lg: {
        weight: '400',
      },
      md: {
        weight: '400',
      },
    },
    subheadings: {
      family: 'PF Din Text Medium, Helvetica',
      weight: '400',
    },
    body: {
      family: 'Roboto',
    },
  },
  components: {
    Button: {
      rounded: 'max',
      primary: {
        hoverBg: '#21528C',
      },
      secondary: {
        hoverBg: '#1EBCF8',
      },
      tertiary: {
        hoverBg: '#1EBCF8',
      },
      white: {
        hoverBg: '#102641',
      },
    },
    PackageCard: {
      radius: '8px',
      stroke: {
        color: '#337ACE',
      },
      label: {
        backgroundColor: '#569CEF',
        radius: '8px',
      },
      title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 'bold',
      },
    },
    Column: {
      image: 'full',
    },
    Price: {
      dollarSign: {
        weight: 'normal',
        // size: '20px',
        // position: 'top', // top, bottom (default), center
        // offsetY: '7px',
      },
      dollar: {
        weight: 'normal',
      },
      cents: {
        size: '20px',
        position: 'bottom', // top, bottom, center (default) // not used anywhere yet
        offsetY: '-8px',
      },
      duration: {
        position: 'bottom', // top, bottom, center (default), underCents
        size: '20px',
        offsetY: '-8px',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '8px',
})
