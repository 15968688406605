import { withTheme } from 'emotion-theming'
import React from 'react'
import CtaBarStyles from '../../../../../gatsby-theme-directv/src/components/CustomStyles/CtaBar.styles'
import GlobalCss from '../../../../../gatsby-theme-directv/src/components/CustomStyles/GlobalCss.styles'
import PriceStyles from '../../../../../gatsby-theme-directv/src/components/CustomStyles/Price.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <CtaBarStyles />
    <PriceStyles />
  </>
))

export default GlobalStyles
